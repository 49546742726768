import type { TypedBroadcastChannel } from 'o365.pwa.declaration.sw.TypedBroadcastChannel.d.ts';

declare module 'o365.pwa.declaration.sw.indexedDBBroadcastChannel.d.ts' {
    export type MessageType = 'App' | 'Database' | 'ObjectStore' | 'Index' | 'PWAState' | 'ServiceWorkerState' | 'SerivceWorkerScriptState' | 'AppResourceState' | 'User' | 'GlobalSetting';

    export interface Message<T extends MessageType> {
        type: T;
    }

    export interface IAppMessage extends Message<'App'> {
        appId: string;
    }

    export interface IDatabaseMessage extends Message<'Database'> {
        appId: string;
        databaseId: string;
    }

    export interface IObjectStoreMessage extends Message<'ObjectStore'> {
        appId: string;
        databaseId: string;
        objectStoreId: string;
    }

    export interface IIndexMessage extends Message<'Index'> {
        appId: string;
        databaseId: string;
        objectStoreId: string;
        indexId: string;
    }

    export interface IPWAStateMessage extends Message<'PWAState'> {
        appId: string;
    }

    export interface IServiceWorkerStateMessage extends Message<'ServiceWorkerState'> {
        appId: string;
    }

    export interface IServiceWorkerScriptStateMessage extends Message<'SerivceWorkerScriptState'> {
        appId: string;
        serviceWorkerScriptId: string;
    }

    export interface IAppResourceStateMessage extends Message<'AppResourceState'> {
        appId: string;
        appResourceId: string;
    }

    export interface IUserMessage extends Message<'User'> { }

    export interface IGlobalSettingMessage extends Message<'GlobalSetting'> { }

    export type EventData = IAppMessage | IDatabaseMessage | IObjectStoreMessage | IIndexMessage | IPWAStateMessage | IServiceWorkerStateMessage | IServiceWorkerScriptStateMessage | IAppResourceStateMessage | IUserMessage | IGlobalSettingMessage;

    export const broadcastChannel: TypedBroadcastChannel<EventData>;
}
